/* accordion module: theme */
.accordion {
  text-align: left;
  list-style: none;
  padding: 0;
}
.accordion:not(:first-child) {
  margin-top: 3.5rem;
}
.accordion .accordion__item {
  border: 0.0625rem solid #C4C4C4;
}
.accordion .accordion__item:not(:first-child) {
  border-top-width: 0;
}
.accordion .accordion__header {
  margin: 0;
}
.accordion .accordion__header button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.6666666667;
  font-family: franklin-gothic-urw, sans-serif;
  color: #000000;
  font-size: 1.375rem;
  display: flex;
  transition: color 0.3s ease-in;
  text-align: left;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 1.4375rem 2.5rem;
}
@media screen and (max-width: 767px) {
  .accordion .accordion__header button {
    line-height: 1.33;
  }
}
@media screen and (max-width: 767px) {
  .accordion .accordion__header button {
    padding-left: 24px;
    padding-right: 30px;
  }
}
.accordion .accordion__header button .accordion__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  margin: 0.375rem -0.375rem -0.125rem auto;
  position: relative;
  padding-left: 2.5rem;
}
@media screen and (max-width: 767px) {
  .accordion .accordion__header button .accordion__icon {
    margin-top: 0;
  }
}
.accordion .accordion__header button .accordion__icon:before, .accordion .accordion__header button .accordion__icon:after {
  display: block;
  content: "";
  position: absolute;
  background: #000000;
  width: 0.25rem;
  height: 1.5rem;
  transition: transform 0.3s ease-in, opacity 0.3s ease-in, background-color 0.3s ease-in;
}
.accordion .accordion__header button .accordion__icon:before {
  transform: rotate(90deg);
}
.accordion .accordion__header button:hover {
  color: #2ca8b3;
}
.accordion .accordion__header button:hover .accordion__icon:before, .accordion .accordion__header button:hover .accordion__icon:after {
  background: #2ca8b3;
}
.accordion .accordion__header button:active {
  color: #000000;
  transition-duration: 0s;
}
.accordion .accordion__header button:active .accordion__icon:before, .accordion .accordion__header button:active .accordion__icon:after {
  background: #000000;
  transition-duration: 0s;
}
.accordion .accordion__panel {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in;
}
.accordion .accordion__panel .accordion__content {
  padding: 2rem 5rem 4rem 5rem;
  opacity: 0;
  position: relative;
  transition: opacity 0.3s ease-in;
  color: #000000;
  font-size: 1rem;
}
@media screen and (max-width: 767px) {
  .accordion .accordion__panel .accordion__content {
    padding: 24px 3rem 48px 3rem;
  }
}
.accordion .accordion__panel ul {
  list-style-type: disc;
}
.accordion .accordion__panel ul ul {
  list-style-type: circle;
}
.accordion .accordion__panel ul ul ul {
  list-style-type: square;
}
.accordion .accordion__item--expanding .accordion__content {
  opacity: 1;
}
.accordion .accordion__item--expanded .accordion__panel {
  overflow: visible;
  height: auto;
}
.accordion .accordion__item--expanded .accordion__panel .accordion__content {
  opacity: 1;
}
.accordion .accordion__item--expanding .accordion__header button .accordion__icon:after,
.accordion .accordion__item--expanded .accordion__header button .accordion__icon:after {
  transform: rotate(90deg);
  opacity: 0;
}

ol.accordion {
  counter-reset: counter;
}
ol.accordion > li {
  counter-increment: counter;
}
ol.accordion > li .accordion__header button span:first-child::before {
  content: counter(counter, decimal-leading-zero);
  padding-right: 0.75rem;
  opacity: 0.33;
}